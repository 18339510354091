import React from "react"

const Navigation = ({ navigation, step, steps }) => {
  const { go } = navigation

  return (
    <ul className="list-none flex flex-row mx-auto justify-between w-full max-w-[1000px] relative">
      {steps
        .filter(item => item.navigation === true)
        .map((item, index) => {
          return (
            <li
              className={`text-center text-xxs relative lg:pb-4 z-10 ${
                step.key >= item.key ? "cursor-pointer" : "pointer-events-none"
              }`}
              key={index}
            >
              <button
                className="focus:outline-none"
                onClick={() => go(item.id)}
                onKeyDown={() => go(item.id)}
                tabIndex={index}
              >
                <div className="px-1 py-1.5 lg:p-1.5 bg-darkgrey">
                  <span
                    className={`w-5 py-0.5 rounded-full border font-bold inline-block transition duration-300 ${
                      step.key >= item.key
                        ? "border-white bg-white text-darkgrey"
                        : "border-gold bg-darkgrey text-white"
                    }`}
                  >
                    {index + 1}
                  </span>
                </div>
                <div className="text-gold absolute whitespace-nowrap left-1/2 transform -translate-x-1/2 font-bold hidden lg:block">
                  {item.title}
                </div>
              </button>
            </li>
          )
        })}
      <hr className="h-px w-full bg-white absolute top-4 z-0" />
    </ul>
  )
}

export default Navigation
