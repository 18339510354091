import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Fade from "react-reveal/Fade"
import Slider from "react-slick"

const PrevArrow = props => {
  const { onClick, className } = props
  const disabled = className.includes("slick-disabled") ? "hidden" : ""
  return (
    <div
      className={`absolute top-1/2 transform -translate-y-1/2 left-3 lg:left-12 cursor-pointer z-20 px-3 focus:outline-none outline-none ${disabled}`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <span className="text-darkgrey flex items-center">
        <i className="fas fa-circle text-2xl"></i>
        <i className="fal fa-chevron-left fa-stack-1x fa-inverse text-sm"></i>
      </span>
    </div>
  )
}

const NextArrow = props => {
  const { onClick, className } = props
  const disabled = className.includes("slick-disabled") ? "hidden" : ""
  return (
    <div
      className={`absolute top-1/2 transform -translate-y-1/2 right-3 lg:right-12 cursor-pointer z-20 px-3 focus:outline-none outline-none ${disabled}`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <span className="text-darkgrey flex items-center">
        <i className="fas fa-circle text-2xl"></i>
        <i className="fal fa-chevron-right fa-stack-1x fa-inverse text-sm"></i>
      </span>
    </div>
  )
}

const WoodsAndColoursSelector = ({ setForm, formData }) => {
  const data = useStaticQuery(graphql`
    query WoodsAndFinishesQuery {
      allPrismicWoodsAndFinishes {
        edges {
          node {
            data {
              title {
                text
              }
              featured_image {
                url(imgixParams: { auto: "format" })
                alt
              }
              door_type
            }
          }
        }
      }
    }
  `)

  const handleClick = (value, doorType) => {
    if (!formData.woods_and_colours[doorType].join(', ').includes(value)) {
      setForm(woodsData => ({
        ...woodsData,
        woods_and_colours: {
          ...woodsData.woods_and_colours,
          [doorType]: [...woodsData.woods_and_colours[doorType], value],
        },
      }))
    } else {
      setForm(woodsData => ({
        ...woodsData,
        woods_and_colours: {
          ...woodsData.woods_and_colours,
          [doorType]: woodsData.woods_and_colours[doorType].filter(
            item => item !== value
          ),
        },
      }))
    }
  }

  const settings = {
    className: "w-full px-6 lg:px-28 pt-8 pb-4",
    slidesToShow: 5,
    speed: 500,
    arrows: true,
    dots: false,
    swipeToSlide: true,
    focusOnSelect: false,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <>
      {formData.door_types_and_styles.internal.length > 0 && (
        <div className="w-full mb-6">
          <div className="px-6 lg:px-28">
            <h3 className="uppercase tracking-widest text-black text-xs font-bold">
              Internal Doors
            </h3>
          </div>
          <Slider {...settings}>
            {data.allPrismicWoodsAndFinishes.edges
              .filter(item => (item.node.data.door_type === "Internal Door" && item.node.data?.title?.text !== 'Bespoke Wood'))
              .map((item, index) => {
                return (
                  <div className="px-4 outline-none">
                    <div
                      className="cursor-pointer focus:outline-none outline-none"
                      onClick={() =>
                        handleClick(item.node.data.title.text, "internal")
                      }
                      onKeyDown={() =>
                        handleClick(item.node.data.title.text, "internal")
                      }
                      role="button"
                      tabIndex={index}
                    >
                      <div className="relative p-3">
                        {formData.woods_and_colours.internal.join(', ').includes(item.node.data.title.text) && (
                          <i className="fas fa-check-circle text-white absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"></i>
                        )}
                        <img
                          className="rounded-full"
                          alt={item.node.data.title.text}
                          src={item.node.data.featured_image.url}
                        />
                      </div>
                      <p className="text-xs text-center">
                        {item.node.data.title.text}
                      </p>
                    </div>
                  </div>
                )
              })}
          </Slider>
        </div>
      )}
      {formData.door_types_and_styles.front.length > 0 && (
        <div className="w-full mb-6">
          <div className="px-6 lg:px-28">
            <h3 className="uppercase tracking-widest text-black text-xs font-bold">
              Front Doors
            </h3>
          </div>
          <Slider {...settings}>
            {data.allPrismicWoodsAndFinishes.edges
              .filter(item => item.node.data.door_type === "Front Door")
              .map((item, index) => {
                return (
                  <div className="px-4 outline-none">
                    <div
                      className="cursor-pointer focus:outline-none outline-none"
                      onClick={() =>
                        handleClick(item.node.data.title.text, "front")
                      }
                      onKeyDown={() =>
                        handleClick(item.node.data.title.text, "front")
                      }
                      role="button"
                      tabIndex={index}
                    >
                      <div className="relative p-3">
                        {formData.woods_and_colours.front.join(', ').includes(item.node.data.title.text) && (
                          <i className="fas fa-check-circle text-white absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"></i>
                        )}
                        <img
                          className="rounded-full"
                          alt={item.node.data.title.text}
                          src={item.node.data.featured_image.url}
                        />
                      </div>
                      <p className="text-xs text-center">
                        {item.node.data.title.text}
                      </p>
                    </div>
                  </div>
                )
              })}
          </Slider>
        </div>
      )}
      {formData.door_types_and_styles.garage.length > 0 && (
        <div className="w-full mb-6">
          <div className="px-6 lg:px-28">
            <h3 className="uppercase tracking-widest text-black text-xs font-bold">
              Garage Doors
            </h3>
          </div>
          <Slider {...settings}>
            {data.allPrismicWoodsAndFinishes.edges
              .filter(item => item.node.data.door_type === "Garage Door")
              .map((item, index) => {
                return (
                  <div className="px-4 outline-none">
                    <div
                      className="cursor-pointer focus:outline-none outline-none"
                      onClick={() =>
                        handleClick(item.node.data.title.text, "garage")
                      }
                      onKeyDown={() =>
                        handleClick(item.node.data.title.text, "garage")
                      }
                      role="button"
                      tabIndex={index}
                    >
                      <div className="relative p-3">
                        {formData.woods_and_colours.garage.join(', ').includes(item.node.data.title.text) && (
                          <i className="fas fa-check-circle text-white absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"></i>
                        )}
                        <img
                          className="rounded-full"
                          alt={item.node.data.title.text}
                          src={item.node.data.featured_image.url}
                        />
                      </div>
                      <p className="text-xs text-center">
                        {item.node.data.title.text}
                      </p>
                    </div>
                  </div>
                )
              })}
          </Slider>
        </div>
      )}
    </>
  )
}

const WoodsAndColours = ({ setForm, formData, navigation }) => {
  const { previous, next } = navigation

  const [errors, setErrors] = useState("");

  const handleNext = () => {
    // Check if at least one wood or paint colour is selected for each door type that is selected from the previous step
    if (formData.door_types_and_styles.internal.length > 0) {
      if (formData.woods_and_colours.internal.length === 0) {
        setErrors("Please select at least one wood or paint colour for the internal door.");
        return;
      }
    }
    if (formData.door_types_and_styles.front.length > 0) {
      if (formData.woods_and_colours.front.length === 0) {
        setErrors("Please select at least one wood or paint colour for the front door.");
        return;
      }
    }
    if (formData.door_types_and_styles.garage.length > 0) {
      if (formData.woods_and_colours.garage.length === 0) {
        setErrors("Please select at least one wood or paint colour for the garage door.");
        return;
      }
    }
    next();
  }

  return (
    <Fade>
      <div className="px-6 lg:px-28">
        <h1 className="font-display text-gold rfs:text-6xl">
          Woods &amp; Colours
        </h1>
        <div className="w-16 separator mt-5 mb-8"></div>
      </div>
      <div className="mb-8">
        <div className="px-6 lg:px-28 mb-8">
          <label
            className="text-uppercase tracking-widest text-black text-xs font-bold block sm:leading-loose leading-tight sm:mb-0 mb-1"
            htmlFor="woods_and_colours"
          >
            WHAT WOOD OR PAINT COLOUR WOULD YOU LIKE?{" "}
            <span className="text-gold">*</span>
          </label>
          <p className="text-xs italic text-black mb-5">
            You can select more than one wood/paint if you wish.
          </p>
        </div>
        <WoodsAndColoursSelector {...{ setForm, formData }} />
      </div>
      <div className="px-6 lg:px-28 flex flex-row">
        <button className="btn btn-black font-bold text-xxs" onClick={previous}>
          Previous
        </button>
        <button
          className="btn btn-black font-bold text-xxs ml-auto"
          onClick={handleNext}
          type="button"
        >
          Next
        </button>
      </div>
      <div className="px-6 lg:px-28 mt-4">
        {errors && (
          <p className="text-red-500 text-xs text-center italic">{errors}</p>
        )}
      </div>      
    </Fade>
  )
}

export default WoodsAndColours
