import React, { useState } from "react"

const Select = ({ children, defaultValue, className, onSelect }) => {
  const [show, setShow] = useState(false)
  const [selected, setSelected] = useState(defaultValue)

  const handleSelect = value => {
    setSelected(value)
    onSelect && onSelect(value)
  }

  const handleClick = () => {
    setShow(!show)
  }

  return (
    <div
      className={`${className} relative text-sm px-6 py-3 border-2 border-gray-100 focus:outline-none w-full rounded-sm outline-none bg-white`}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <div className="relative">
        {selected}
        <i
          className={`far ${
            show ? "fa-chevron-up" : "fa-chevron-down"
          } text-gold text-sm absolute top-1/2 right-0 transform -translate-y-1/2`}
        ></i>
      </div>
      <div
        className={`absolute left-0 top-full border-gray-100 bg-white w-full z-20 ${
          show ? "block" : "hidden"
        }`}
      >
        {children.map((item, index) => {
          return (
            <div
              className="px-6 py-3"
              key={index}
              onClick={() => handleSelect(item.props.value)}
              onKeyDown={() => handleSelect(item.props.value)}
              role="button"
              tabIndex={index}
            >
              {item.props.value}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Select
