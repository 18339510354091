import React, { useState } from "react"
import Breadcrumbs from "src/utils/Breadcrumbs"
import { navigate } from "gatsby"
import Seo from "src/utils/Seo"
import { useStep } from "react-hooks-helper"
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from "react-netlify-forms"
import { useForm } from "react-hook-form"
import { useCookies } from "react-cookie"

import Navigation from "../components/RequestAQuote/Navigation"
import Introduction from "../components/RequestAQuote/Introduction"
import DoorTypeAndStyle from "../components/RequestAQuote/DoorTypeAndStyle"
import WoodsAndColours from "../components/RequestAQuote/WoodsAndColours"
import Configurations from "../components/RequestAQuote/Configurations"
import Quantity from "../components/RequestAQuote/Quantity"
import ProjectInfo from "../components/RequestAQuote/ProjectInfo"
import YourDetails from "../components/RequestAQuote/YourDetails"
import Summary from "../components/RequestAQuote/Summary"

const crumbs = [
  { label: "Request a Quote", href: `/request-a-quote`, active: true },
]

const steps = [
  {
    key: 0,
    id: "introduction",
    title: "Introduction",
    navigation: false,
  },
  {
    key: 1,
    id: "door_type_and_style",
    title: "Door Type & Style",
    navigation: true,
  },
  {
    key: 2,
    id: "woods_and_colours",
    title: "Woods & Colours",
    navigation: true,
  },
  {
    key: 3,
    id: "configurations",
    title: "Configurations",
    navigation: true,
  },
  {
    key: 4,
    id: "quantity",
    title: "Quantity",
    navigation: true,
  },
  {
    key: 5,
    id: "project_info",
    title: "Project Information",
    navigation: true,
  },
  {
    key: 6,
    id: "your_details",
    title: "Your Details",
    navigation: true,
  },
  {
    key: 7,
    id: "summary",
    title: "Summary",
    navigation: true,
  },
]

const defaultData = {
  door_types_and_styles: {
    internal: [],
    front: [],
    garage: [],
  },
  woods_and_colours: {
    internal: [],
    front: [],
    garage: [],
  },
  configurations: {
    internal: [],
    front: [],
    garage: [],
  },
  quantity: {
    internal: "",
    front: "",
    garage: "",
  },
  project_type: "",
  project_stage: "",
  project_involvement: "",
  first_name: "",
  last_name: "",
  email_address: "",
  phone_number: "",
  consent: "",
}

const RequestAQuote = () => {
  const { register, handleSubmit } = useForm()
  const [formData, setForm] = useState(defaultData)
  const { step, navigation } = useStep({ initialStep: 0, steps })
  const { id } = step
  const [cookies] = useCookies()
  const gclid = cookies.gclid

  const netlify = useNetlifyForm({
    name: "request-a-quote",
    action: "/request-a-quote/thank-you",
    honeypotName: "bot-field",
    onSuccess: (response, context) => {
      window.dataLayer.push({event: 'form-submission', formName: 'request-a-quote'});
      navigate("/request-a-quote/thank-you")
    },
  })

  const onSubmit = data => {
    
    const postData = {
      internal_doors_styles: formData.door_types_and_styles.internal.join(', '),
      internal_woods_and_colours: formData.woods_and_colours.internal.join(', '),
      internal_configurations: formData.configurations.internal.join(', '),
      internal_quantity: formData.quantity.internal,
      front_doors_styles: formData.door_types_and_styles.front.join(', '),
      front_woods_and_colours: formData.woods_and_colours.front.join(', '),
      front_configurations: formData.configurations.front.join(', '),
      front_quantity: formData.quantity.front,
      garage_doors_styles: formData.door_types_and_styles.garage.join(', '),
      garage_woods_and_colours: formData.woods_and_colours.garage.join(', '),
      garage_configurations: formData.configurations.garage.join(', '),
      garage_quantity: formData.quantity.garage,
      project_type: formData.project_type,
      project_stage: formData.project_stage,
      project_involvement: formData.project_involvement,
      first_name: formData.first_name,
      last_name: formData.last_name,
      email_address: formData.email_address,
      phone_number: formData.phone_number,
    }
    
    netlify.handleSubmit(null, postData)
  }

  const props = { formData, setForm, navigation, onSubmit }

  const RenderFormStep = id => {
    switch (id) {
      case "introduction":
        return <Introduction {...props} />
      case "door_type_and_style":
        return <DoorTypeAndStyle {...props} />
      case "woods_and_colours":
        return <WoodsAndColours {...props} />
      case "configurations":
        return <Configurations {...props} />
      case "quantity":
        return <Quantity {...props} />
      case "project_info":
        return <ProjectInfo {...props} />
      case "your_details":
        return <YourDetails {...props} />
      case "summary":
        return <Summary {...props} />
      default:
        return null
    }
  }

  return (
    <>
      <Seo title="Request a Quote" />
      <section className="bg-darkgrey pl-6 lg:pl-0 px-6 lg:px-14 py-3 flex flex-row items-center">
        <Breadcrumbs theme="dark" data={crumbs} className="hidden lg:block" />
        <Navigation {...{ navigation, step, steps }} />
      </section>
      <NetlifyFormProvider {...netlify}>
        <NetlifyFormComponent
          onSubmit={handleSubmit(onSubmit)}
          id="request-a-quote-form"
        >
          <section
            className="flex flex-row bg-offwhite"
            style={{ minHeight: "60vh" }}
          >
            <div className="w-full max-w-screen-lg mx-auto py-14 max-h-100 bg-offwhite flex flex-col justify-center">
              {RenderFormStep(id)}
            </div>
          </section>
          <Honeypot />
          <input
            {...register("internal_doors_styles")}
            type="hidden"
            name="internal_doors_styles"
            id="internal_doors_styles"
            value={formData.door_types_and_styles.internal.join(", ")}
          />
          <input
            {...register("front_doors_styles")}
            type="hidden"
            name="front_doors_styles"
            id="front_doors_styles"
            value={formData.door_types_and_styles.front.join(", ")}
          />
          <input
            {...register("garage_doors_styles")}
            type="hidden"
            name="garage_doors_styles"
            id="garage_doors_styles"
            value={formData.door_types_and_styles.garage.join(", ")}
          />
          <input
            {...register("internal_woods_and_colours")}
            type="hidden"
            name="internal_woods_and_colours"
            id="internal_woods_and_colours"
            value={formData.woods_and_colours.internal.join(", ")}
          />
          <input
            {...register("front_woods_and_colours")}
            type="hidden"
            name="front_woods_and_colours"
            id="front_woods_and_colours"
            value={formData.woods_and_colours.front.join(", ")}
          />
          <input
            {...register("garage_woods_and_colours")}
            type="hidden"
            name="garage_woods_and_colours"
            id="garage_woods_and_colours"
            value={formData.woods_and_colours.garage.join(", ")}
          />
          <input
            {...register("internal_configurations")}
            type="hidden"
            name="internal_configurations"
            id="internal_configurations"
            value={formData.configurations.internal.join(", ")}
          />
          <input
            {...register("front_configurations")}
            type="hidden"
            name="front_configurations"
            id="front_configurations"
            value={formData.configurations.front.join(", ")}
          />
          <input
            {...register("garage_configurations")}
            type="hidden"
            name="garage_configurations"
            id="garage_configurations"
            value={formData.configurations.garage.join(", ")}
          />
          <input
            {...register("internal_quantity")}
            type="hidden"
            name="internal_quantity"
            id="internal_quantity"
            value={formData.quantity.internal}
          />
          <input
            {...register("front_quantity")}
            type="hidden"
            name="front_quantity"
            id="front_quantity"
            value={formData.quantity.front}
          />
          <input
            {...register("garage_quantity")}
            type="hidden"
            name="garage_quantity"
            id="garage_quantity"
            value={formData.quantity.garage}
          />
          <input
            {...register("project_type")}
            type="hidden"
            name="project_type"
            id="project_type"
            value={formData.project_type}
          />
          <input
            {...register("project_stage")}
            type="hidden"
            name="project_stage"
            id="project_stage"
            value={formData.project_stage}
          />
          <input
            {...register("project_involvement")}
            type="hidden"
            name="project_involvement"
            id="project_involvement"
            value={formData.project_involvement}
          />
          <input
            {...register("first_name")}
            type="hidden"
            name="first_name"
            id="first_name"
            value={formData.first_name}
          />
          <input
            {...register("last_name")}
            type="hidden"
            name="last_name"
            id="last_name"
            value={formData.last_name}
          />
          <input
            {...register("email_address")}
            type="hidden"
            name="email_address"
            id="email_address"
            value={formData.email_address}
          />
          <input
            {...register("phone_number")}
            type="hidden"
            name="phone_number"
            id="phone_number"
            value={formData.phone_number}
          />
          <input
            type="hidden"
            name="gclid"
            id="gclid"
            value={gclid}
          />
        </NetlifyFormComponent>
      </NetlifyFormProvider>
    </>
  )
}

export default RequestAQuote
