import React, { useState } from "react"
import Fade from "react-reveal/Fade"

const YourDetails = ({ setForm, formData, navigation }) => {
  const { previous, next } = navigation

  const [errors, setErrors] = useState("")

  const handleChange = (key, value) => {
    setForm(detailsData => ({
      ...detailsData,
      [key]: value,
    }))
  }

  const handleNext = () => {
    if (formData.first_name === "") {
      setErrors("Please enter your first name.")
      return
    }
    if (formData.last_name === "") {
      setErrors("Please enter your last name.")
      return
    }
    if (formData.email_address === "") {
      setErrors("Please enter your email address.")
      return
    }
    if (formData.phone_number === "") {
      setErrors("Please enter your phone number.")
      return
    }
    next()
  }

  return (
    <Fade>
      <div className="px-6 lg:px-28">
        <h1 className="font-display text-gold rfs:text-6xl">Your Details</h1>
        <div className="w-16 separator mt-5 mb-8"></div>
      </div>
      <div className="mb-6 flex flex-row sm:flex-nowrap flex-wrap px-6 lg:px-28 sm:space-x-3">
        <div className="sm:w-1/2 w-full sm:mb-0 mb-6">
          <label
            className="block uppercase tracking-widest text-black text-xs font-bold mb-2"
            htmlFor="first_name"
          >
            First Name <span className="text-gold">*</span>
          </label>
          <input
            className="text-sm px-6 py-3 border focus:border-black border-gray-100 w-full rounded-sm outline-none"
            type="text"
            id="first_name"
            name="first_name"
            onChange={event => handleChange("first_name", event.target.value)}
            placeholder="First name*"
            value={formData.first_name}
          />
        </div>
        <div className="sm:w-1/2 w-full">
          <label
            className="block uppercase tracking-widest text-black text-xs font-bold mb-2"
            htmlFor="last_name"
          >
            Last Name <span className="text-gold">*</span>
          </label>
          <input
            className="text-sm px-6 py-3 border focus:border-black border-gray-100 w-full rounded-sm outline-none"
            type="text"
            id="last_name"
            name="last_name"
            onChange={event => handleChange("last_name", event.target.value)}
            placeholder="Last name*"
            value={formData.last_name}
          />
        </div>
      </div>
      <div className="mb-6 flex flex-row px-6 lg:px-28 space-x-3">
        <div className="w-full">
          <label
            className="block uppercase tracking-widest text-black text-xs font-bold mb-2"
            htmlFor="email_address"
          >
            Email address <span className="text-gold">*</span>
          </label>
          <input
            className="text-sm px-6 py-3 border focus:border-black border-gray-100 w-full rounded-sm outline-none"
            type="email"
            id="email_address"
            name="email_address"
            onChange={event =>
              handleChange("email_address", event.target.value)
            }
            placeholder="Email address*"
            value={formData.email_address}
          />
        </div>
      </div>
      <div className="mb-10 flex flex-row px-6 lg:px-28 space-x-3">
        <div className="w-full">
          <label
            className="block uppercase tracking-widest text-black text-xs font-bold mb-2"
            htmlFor="phone_number"
          >
            Phone number <span className="text-gold">*</span>
          </label>
          <input
            className="text-sm px-6 py-3 border focus:border-black border-gray-100 w-full rounded-sm outline-none"
            type="text"
            id="phone_number"
            name="phone_number"
            onChange={event => handleChange("phone_number", event.target.value)}
            placeholder="Phone number*"
            value={formData.phone_number}
          />
        </div>
      </div>
      <div className="mb-14 px-6 lg:px-28">
        <label className="flex justify-start items-start gap-2">
          <input
            type="checkbox"
            className="opacity-0 absolute"
            name="consent"
            onChange={event => handleChange("consent", event.target.value)}
          />
          <i className="far fa-circle"></i>
          <i className="far fa-check-circle"></i>
          <div className="formcheckboxlabel" htmlFor="consent">
            I agree to receive occasional emails containing the latest product
            information and news from Deuren.
          </div>
        </label>
      </div>
      <div className="px-6 lg:px-28 flex flex-row">
        <button className="btn btn-black font-bold text-xxs" onClick={previous}>
          Previous
        </button>
        <button
          className="btn btn-black font-bold text-xxs ml-auto"
          onClick={handleNext}
          type="button"
        >
          Next
        </button>
      </div>
      <div className="px-6 lg:px-28 mt-4">
        {errors && (
          <p className="text-red-500 text-xs text-center italic">{errors}</p>
        )}
      </div>                 
    </Fade>
  )
}

export default YourDetails
