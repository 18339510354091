import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Fade from "react-reveal/Fade"
import Slider from "react-slick"

const PrevArrow = props => {
  const { onClick, className } = props
  const disabled = className.includes("slick-disabled") ? "hidden" : ""
  return (
    <div
      className={`absolute top-1/2 transform -translate-y-1/2 left-3 lg:left-12 cursor-pointer z-20 px-3 focus:outline-none outline-none ${disabled}`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <span className="text-darkgrey flex items-center">
        <i className="fas fa-circle text-2xl"></i>
        <i className="fal fa-chevron-left fa-stack-1x fa-inverse text-sm"></i>
      </span>
    </div>
  )
}

const NextArrow = props => {
  const { onClick, className } = props
  const disabled = className.includes("slick-disabled") ? "hidden" : ""
  return (
    <div
      className={`absolute top-1/2 transform -translate-y-1/2 right-3 lg:right-12 cursor-pointer z-20 px-3 focus:outline-none outline-none ${disabled}`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <span className="text-darkgrey flex items-center">
        <i className="fas fa-circle text-2xl"></i>
        <i className="fal fa-chevron-right fa-stack-1x fa-inverse text-sm"></i>
      </span>
    </div>
  )
}

const ConfigurationSelector = ({ setForm, formData }) => {
  const data = useStaticQuery(graphql`
    query ConfigurationsQuery {
      allPrismicConfigurations {
        edges {
          node {
            data {
              title {
                text
              }
              door_image {
                url
                alt
              }
              configuration_type
            }
          }
        }
      }
    }
  `)

  const useDoorType = {
    internal: "Internal Door",
    front: "Front Door",
    garage: "Garage Door",
  }

  const handleClick = (value, doorType) => {
    if (!formData.configurations[doorType].join(', ').includes(value)) {
      setForm(configData => ({
        ...configData,
        configurations: {
          ...configData.configurations,
          [doorType]: [...configData.configurations[doorType], value],
        },
      }))
    } else {
      setForm(configData => ({
        ...configData,
        configurations: {
          ...configData.configurations,
          [doorType]: configData.configurations[doorType].filter(
            item => item !== value
          ),
        },
      }))
    }
  }

  const settings = {
    className: "w-full px-6 lg:px-28 pt-8 pb-4",
    slidesToShow: 5,
    speed: 500,
    arrows: true,
    dots: false,
    swipeToSlide: true,
    focusOnSelect: false,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <>
      {formData.door_types_and_styles.internal.length > 0 && (
        <div className="w-full mb-6">
          <div className="px-6 lg:px-28">
            <h3 className="uppercase tracking-widest text-black text-xs font-bold">
              Internal Doors
            </h3>
          </div>
          <Slider {...settings}>
            {data.allPrismicConfigurations.edges
              .filter(
                item =>
                  item.node.data.configuration_type === useDoorType["internal"]
              )
              .map((item, index) => {
                return (
                  <div className="px-8 outline-none">
                    <div
                      className="block relative cursor-pointer focus:outline-none outline-none"
                      onClick={() =>
                        handleClick(item.node.data.title.text, "internal")
                      }
                      onKeyDown={() =>
                        handleClick(item.node.data.title.text, "internal")
                      }
                      role="button"
                      tabIndex={index}
                    >
                      { formData.configurations.internal.join(', ').includes(item.node.data.title.text) && (
                        <i className="fas fa-check-circle text-white absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"></i>
                      )}
                      <img
                        alt={item.node.data.door_image.alt}
                        src={item.node.data.door_image.url}
                      />
                      <p className="text-xs text-center">
                        {item.node.data.title.text}
                      </p>
                    </div>
                  </div>
                )
              })}
          </Slider>
        </div>
      )}
      {formData.door_types_and_styles.front.length > 0 && (
        <div className="w-full mb-6">
          <div className="px-6 lg:px-28">
            <h3 className="uppercase tracking-widest text-black text-xs font-bold">
              Front Doors
            </h3>
          </div>
          <Slider {...settings}>
            {data.allPrismicConfigurations.edges
              .filter(
                item =>
                  item.node.data.configuration_type === useDoorType["front"]
              )
              .map((item, index) => {
                return (
                  <div className="px-8 outline-none">
                    <div
                      className="block relative cursor-pointer focus:outline-none outline-none"
                      onClick={() =>
                        handleClick(item.node.data.title.text, "front")
                      }
                      onKeyDown={() =>
                        handleClick(item.node.data.title.text, "front")
                      }
                      role="button"
                      tabIndex={index}
                    >
                      {formData.configurations.front.join(', ').includes(item.node.data.title.text) && (
                        <i className="fas fa-check-circle text-white absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"></i>
                      )}
                      <img
                        alt={item.node.data.door_image.alt}
                        src={item.node.data.door_image.url}
                      />
                      <p className="text-xs text-center">
                        {item.node.data.title.text}
                      </p>
                    </div>
                  </div>
                )
              })}
          </Slider>
        </div>
      )}
      {formData.door_types_and_styles.garage.length > 0 && (
        <div className="w-full mb-6">
          <div className="px-6 lg:px-28">
            <h3 className="uppercase tracking-widest text-black text-xs font-bold">
              Garage Doors
            </h3>
          </div>
          <Slider {...settings}>
            {data.allPrismicConfigurations.edges
              .filter(
                item =>
                  item.node.data.configuration_type === useDoorType["garage"]
              )
              .map((item, index) => {
                return (
                  <div className="px-8 outline-none">
                    <div
                      className="block relative cursor-pointer focus:outline-none outline-none"
                      onClick={() =>
                        handleClick(item.node.data.title.text, "garage")
                      }
                      onKeyDown={() =>
                        handleClick(item.node.data.title.text, "garage")
                      }
                      role="button"
                      tabIndex={index}
                    >
                      {formData.configurations.garage.join(', ').includes(item.node.data.title.text) && (
                        <i className="fas fa-check-circle text-white absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"></i>
                      )}
                      <img
                        alt={item.node.data.door_image.alt}
                        src={item.node.data.door_image.url}
                      />
                      <p className="text-xs text-center">
                        {item.node.data.title.text}
                      </p>
                    </div>
                  </div>
                )
              })}
          </Slider>
        </div>
      )}
    </>
  )
}

const Configurations = ({ setForm, formData, navigation }) => {
  const { previous, next } = navigation

  const [errors, setErrors] = useState("");

  const handleNext = () => {
    if (formData.door_types_and_styles.internal.length > 0 ) {
      if (formData.configurations.internal.length === 0) {
        setErrors("Please select at least one configuration for internal doors.")
        return
      }
    }
    if (formData.door_types_and_styles.front.length > 0 ) {
      if (formData.configurations.front.length === 0) {
        setErrors("Please select at least one configuration for front doors.")
        return
      }
    }
    if (formData.door_types_and_styles.garage.length > 0 ) {
      if (formData.configurations.garage.length === 0) {
        setErrors("Please select at least one configuration for garage doors.")
        return
      }
    }
    next()
  }

  return (
    <Fade>
      <div className="px-6 lg:px-28">
        <h1 className="font-display text-gold rfs:text-6xl">Configurations</h1>
        <div className="w-16 separator mt-5 mb-8"></div>
      </div>
      <div className="mb-8">
        <div className="px-6 lg:px-28 mb-8">
          <label
            className="uppercase tracking-widest text-black text-xs font-bold block sm:leading-loose leading-tight sm:mb-0 mb-1"
            htmlFor="configurations"
          >
            What configurations are you interested in?{" "}
            <span className="text-gold">*</span>
          </label>
          <p className="text-xs italic text-black mb-5">
            You can select more than one configuration if you wish.
          </p>
        </div>
        <ConfigurationSelector {...{ setForm, formData }} />
      </div>
      <div className="px-6 lg:px-28 flex flex-row">
        <button className="btn btn-black font-bold text-xxs" onClick={previous}>
          Previous
        </button>
        <button
          className="btn btn-black font-bold text-xxs ml-auto"
          onClick={handleNext}
          type="button"
        >
          Next
        </button>
      </div>
      <div className="px-6 lg:px-28 mt-4">
        {errors && (
          <p className="text-red-500 text-xs text-center italic">{errors}</p>
        )}
      </div>            
    </Fade>
  )
}

export default Configurations
