import React from "react"
import Fade from "react-reveal/Fade"

const Summary = ({ setForm, formData, navigation, onSubmit }) => {
  const { previous } = navigation

  return (
    <Fade>
      <div className="px-6 lg:px-28">
        <h1 className="font-display text-gold rfs:text-6xl">Summary</h1>
        <div className="w-16 separator mt-5 mb-10"></div>
      </div>
      <div className="px-6 lg:px-28 flex flex-row flex-wrap mb-8">
        <div className="sm:w-1/3 w-full sm:mb-0 mb-3">
          <h3 className="block text-gold text-display text-sm font-bold mb-2">
            Door Type &amp; Style
          </h3>
        </div>
        <div className="sm:w-2/3 w-full mb-3 flex flex-row flex-wrap">
          {formData.door_types_and_styles.internal.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Internal Doors
              </dt>
              <dd className="text-sm mb-4">
                {formData.door_types_and_styles.internal.join(", ")}
              </dd>
            </dl>
          )}
          {formData.door_types_and_styles.front.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Front Doors
              </dt>
              <dd className="text-sm mb-4">
                {formData.door_types_and_styles.front.join(", ")}
              </dd>
            </dl>
          )}
          {formData.door_types_and_styles.garage.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Garage Doors
              </dt>
              <dd className="text-sm mb-4">
                {formData.door_types_and_styles.garage.join(", ")}
              </dd>
            </dl>
          )}
        </div>
        <div className="w-full h-px bg-gold"></div>
      </div>
      <div className="px-6 lg:px-28 flex flex-row flex-wrap mb-8">
        <div className="sm:w-1/3 w-full sm:mb-0 mb-3">
          <h3 className="block text-gold text-display text-sm font-bold mb-2">
            Woods &amp; Colours
          </h3>
        </div>
        <div className="sm:w-2/3 w-full mb-3 flex flex-row flex-wrap">
          {formData.door_types_and_styles.internal.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Internal Doors
              </dt>
              <dd className="text-sm mb-4">
                {formData.woods_and_colours.internal.join(", ")}
              </dd>
            </dl>
          )}
          {formData.door_types_and_styles.front.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Front Doors
              </dt>
              <dd className="text-sm mb-4">
                {formData.woods_and_colours.front.join(", ")}
              </dd>
            </dl>
          )}
          {formData.door_types_and_styles.garage.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Garage Doors
              </dt>
              <dd className="text-sm mb-4">
                {formData.woods_and_colours.garage.join(", ")}
              </dd>
            </dl>
          )}
        </div>
        <div className="w-full h-px bg-gold"></div>
      </div>
      <div className="px-6 lg:px-28 flex flex-row flex-wrap mb-8">
        <div className="sm:w-1/3 w-full sm:mb-0 mb-3">
          <h3 className="block text-gold text-display text-sm font-bold mb-2">
            Configurations
          </h3>
        </div>
        <div className="sm:w-2/3 w-full mb-3 flex flex-row flex-wrap">
          {formData.door_types_and_styles.internal.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Internal Doors
              </dt>
              <dd className="text-sm mb-4">
                {formData.configurations.internal.join(", ")}
              </dd>
            </dl>
          )}
          {formData.door_types_and_styles.front.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Front Doors
              </dt>
              <dd className="text-sm mb-4">
                {formData.configurations.front.join(", ")}
              </dd>
            </dl>
          )}
          {formData.door_types_and_styles.garage.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Garage Doors
              </dt>
              <dd className="text-sm mb-4">
                {formData.configurations.garage.join(", ")}
              </dd>
            </dl>
          )}
        </div>
        <div className="w-full h-px bg-gold"></div>
      </div>
      <div className="px-6 lg:px-28 flex flex-row flex-wrap mb-8">
        <div className="sm:w-1/3 w-full sm:mb-0 mb-3">
          <h3 className="block text-gold text-display text-sm font-bold mb-2">
            Quantity
          </h3>
        </div>
        <div className="sm:w-2/3 w-full mb-3 flex flex-row flex-wrap">
          {formData.door_types_and_styles.internal.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Internal Doors
              </dt>
              <dd className="text-sm mb-4">{formData.quantity.internal}</dd>
            </dl>
          )}
          {formData.door_types_and_styles.front.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Front Doors
              </dt>
              <dd className="text-sm mb-4">{formData.quantity.front}</dd>
            </dl>
          )}
          {formData.door_types_and_styles.garage.length > 0 && (
            <dl className="sm:w-1/2 w-full">
              <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
                Garage Doors
              </dt>
              <dd className="text-sm mb-4">{formData.quantity.garage}</dd>
            </dl>
          )}
        </div>
        <div className="w-full h-px bg-gold"></div>
      </div>
      <div className="px-6 lg:px-28 flex flex-row flex-wrap mb-8">
        <div className="sm:w-1/3 w-full sm:mb-0 mb-3">
          <h3 className="block text-gold text-display text-sm font-bold mb-2">
            Project Involvement
          </h3>
        </div>
        <div className="sm:w-2/3 w-full mb-3 flex flex-row flex-wrap">
          <dl className="sm:w-1/2 w-full">
            <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
              Project Type
            </dt>
            <dd className="text-sm mb-4">{formData.project_type}</dd>
          </dl>
          <dl className="sm:w-1/2 w-full">
            <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
              Project Stage
            </dt>
            <dd className="text-sm mb-4">{formData.project_stage}</dd>
          </dl>
          <dl className="sm:w-1/2 w-full">
            <dt className="block uppercase tracking-widest text-black text-xs font-bold mb-1">
              Project Involvement
            </dt>
            <dd className="text-sm mb-4">{formData.project_involvement}</dd>
          </dl>
        </div>
        <div className="w-full h-px bg-gold"></div>
      </div>
      <div className="px-6 lg:px-28 flex flex-row flex-wrap mb-8">
        <div className="sm:w-1/3 w-full sm:mb-0 mb-3">
          <h3 className="block text-gold text-display text-sm font-bold mb-2">
            Your Details
          </h3>
        </div>
        <div className="sm:w-2/3 w-full mb-3 flex flex-row flex-wrap">
          <dl className="sm:w-1/2 w-full">
            <dd className="text-sm mb-2 flex">
              {formData.first_name} {formData.last_name}
            </dd>
            <dd className="text-sm mb-2">{formData.email_address}</dd>
            <dd className="text-sm mb-4">{formData.phone_number}</dd>
          </dl>
        </div>
        <div className="w-full h-px bg-gold"></div>
      </div>
      <div className="px-6 lg:px-28 flex flex-row">
        <button className="btn btn-black font-bold text-xxs" onClick={previous}>
          Previous
        </button>
        <input
          type="submit"
          className="btn btn-black font-bold text-xxs ml-auto cursor-pointer"
          value="Submit"
        />
      </div>
    </Fade>
  )
}

export default Summary
