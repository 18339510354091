import React from "react"
import Fade from "react-reveal/Fade"

const Introduction = ({ setForm, formData, navigation }) => {
  const { next } = navigation

  return (
    <Fade>
      <div className="px-6 lg:px-28 flex flex-col items-center text-center lg:items-start lg:text-left">
        <h1 className="font-display text-gold rfs:text-6xl">Request a Quote</h1>
        <div className="w-16 separator mt-5 mb-14"></div>
        <p className="text-dark text-sm lg:pr-14 mb-14">
          From handcrafted interiors doors to precision made, striking front
          doors, it all starts with us finding out your exact requirements. If
          you’d like to talk to us about your new door, which is made in the UK
          by our expert craftsmen, then simply fill in the form below. A member
          of the Deuren will then get in touch to further discuss your needs.
        </p>
        <button
          className="btn btn-black text-uppercase text-white w-50 font-bold text-xxs"
          onClick={next}
        >
          Let's get started
        </button>
      </div>
    </Fade>
  )
}

export default Introduction
