import React, { useState } from "react"
import Fade from "react-reveal/Fade"

const Quantity = ({ setForm, formData, navigation }) => {
  const { previous, next } = navigation

  const [errors, setErrors] = useState("");

  const handleChange = (doorType, value) => {
    value = value < 0 ? 0 : value
    setForm(quantityData => ({
      ...quantityData,
      quantity: {
        ...quantityData.quantity,
        [doorType]: value,
      },
    }))
  }

  const handleNext = () => {
    if (formData.door_types_and_styles.internal.length > 0 ) {
      if (formData.quantity.internal === 0 || formData.quantity.internal === "") {
        setErrors("Please select a quantity for internal doors.")
        return
      }
    }
    if (formData.door_types_and_styles.front.length > 0 ) {
      if (formData.quantity.front === 0 || formData.quantity.front === "") {
        setErrors("Please select a quantity for front doors.")
        return
      }
    }
    if (formData.door_types_and_styles.garage.length > 0 ) {
      if (formData.quantity.garage === 0 || formData.quantity.garage === "") {
        setErrors("Please select a quantity for garage doors.")
        return
      }
    }
    next()
  }    

  return (
    <Fade>
      <div className="px-6 lg:px-28">
        <h1 className="font-display text-gold rfs:text-6xl">Quantity</h1>
        <div className="w-16 separator mt-5 mb-8"></div>
      </div>
      <div className="mb-8">
        <div className="px-6 lg:px-28">
          <label
            className="block uppercase tracking-widest text-black text-xs font-bold mb-10 w-9/12 sm:leading-loose leading-tight"
            htmlFor="quantity"
          >
            How many doors do you want to buy?{" "}
            <span className="text-gold">*</span>
          </label>
          {formData.door_types_and_styles.internal.length > 0 && (
            <div className="mb-5">
              <label
                className="block uppercase tracking-widest text-black text-xs font-bold mb-2"
                htmlFor="internal"
              >
                Internal Doors
              </label>
              <input
                className="text-sm px-6 py-3 border focus:border-black border-gray-100 w-full rounded-sm outline-none"
                type="number"
                min="0"
                id="internal"
                name="internal"
                onChange={event => handleChange("internal", event.target.value)}
                placeholder="Please type the quantity here..."
                value={formData.quantity.internal}
              />
            </div>
          )}
          {formData.door_types_and_styles.front.length > 0 && (
            <div className="mb-5">
              <label
                className="block uppercase tracking-widest text-black text-xs font-bold mb-2"
                htmlFor="front"
              >
                Front Doors
              </label>
              <input
                className="text-sm px-6 py-3 border focus:border-black border-gray-100 w-full rounded-sm outline-none"
                type="number"
                min="0"
                id="front"
                name="front"
                onChange={event => handleChange("front", event.target.value)}
                placeholder="Please type the quantity here..."
                value={formData.quantity.front}
              />
            </div>
          )}
          {formData.door_types_and_styles.garage.length > 0 && (
            <div className="mb-5">
              <label
                className="block uppercase tracking-widest text-black text-xs font-bold mb-2"
                htmlFor="garage"
              >
                Garage Doors
              </label>
              <input
                className="text-sm px-6 py-3 border focus:border-black border-gray-100 w-full rounded-sm outline-none"
                type="number"
                min="0"
                id="garage"
                name="garage"
                onChange={event => handleChange("garage", event.target.value)}
                placeholder="Please type the quantity here..."
                value={formData.quantity.garage}
              />
            </div>
          )}
        </div>
      </div>
      <div className="px-6 lg:px-28 flex flex-row">
        <button className="btn btn-black font-bold text-xxs" onClick={previous}>
          Previous
        </button>
        <button
          className="btn btn-black font-bold text-xxs ml-auto"
          onClick={handleNext}
          type="button"
        >
          Next
        </button>
      </div>
      <div className="px-6 lg:px-28 mt-4">
        {errors && (
          <p className="text-red-500 text-xs text-center italic">{errors}</p>
        )}
      </div>                 
    </Fade>
  )
}

export default Quantity
