import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import Select from "./Select"
import data from "src/components/FormJourneyStageInterest/data.json"

const ProjectInfo = ({ setForm, formData, navigation }) => {
  const { previous, next } = navigation

  const [errors, setErrors] = useState("")

  const projectTypeCallback = value => {
    setForm(data => ({
      ...data,
      project_type: value,
    }))
  }

  const projectStageCallback = value => {
    setForm(data => ({
      ...data,
      project_stage: value,
    }))
  }

  const projectInvolvementCallback = value => {
    setForm(data => ({
      ...data,
      project_involvement: value,
    }))
  }

  const handleNext = () => {
    if (formData.project_type === "") {
      setErrors("Please select a project type.")
      return
    }
    if (formData.project_stage === "") {
      setErrors("Please select a project stage.")
      return
    }
    if (formData.project_involvement === "") {
      setErrors("Please select a project involvement.")
      return
    }
    next()
  }

  return (
    <Fade>
      <div className="px-6 lg:px-28">
        <h1 className="font-display text-gold rfs:text-6xl">
          Project Information
        </h1>
        <div className="w-16 separator mt-5 mb-8"></div>
      </div>
      <div className="mb-8 relative z-10">
        <div className="px-6 lg:px-28 mb-6 relative">
          <label
            className="block uppercase tracking-widest text-black text-xs font-bold sm:leading-loose leading-tight mb-2"
            htmlFor="quantity"
          >
            What type of project are you working on?{" "}
            <span className="text-gold">*</span>
          </label>
          <Select
            defaultValue={formData.project_type || "Select an option"}
            onSelect={projectTypeCallback}
          >
            <option value="New Build">New Build</option>
            <option value="Replacement Doors">Replacement Doors</option>
            <option value="Major Renovation">Major Renovation</option>
            {/* <option value="Other">Other</option> */}
          </Select>
        </div>
        {formData.project_type &&
        <div className="px-6 lg:px-28 mb-6 position-relative">
          <label
            className="block uppercase tracking-widest text-black text-xs font-bold sm:leading-loose leading-tight mb-2"
            htmlFor="quantity"
          >
            At what stage is your project? <span className="text-gold">*</span>
          </label>
          <Select
            defaultValue={formData.project_stage || "Select an option"}
            onSelect={projectStageCallback}
          >
            {data.stage.find(({ name }) => name === formData.project_type ).options.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </Select>
        </div>
        }
        <div className="px-6 lg:px-28 mb-6 position-relative">
          <label
            className="block uppercase tracking-widest text-black text-xs font-bold sm:leading-loose leading-tight mb-2"
            htmlFor="quantity"
          >
            What is your involvement in the project?{" "}
            <span className="text-gold">*</span>
          </label>
          <Select
            defaultValue={formData.project_involvement || "Select an option"}
            onSelect={projectInvolvementCallback}
          >
            <option value="Homeowner">Homeowner</option>
            <option value="Developer">Developer</option>
            <option value="Architect">Architect</option>
            <option value="Interior Designer">Interior Designer</option>
            <option value="Other">Other</option>
          </Select>
        </div>
      </div>
      <div className="px-6 lg:px-28 flex flex-row">
        <button className="btn btn-black font-bold text-xxs" onClick={previous}>
          Previous
        </button>
        <button
          className="btn btn-black font-bold text-xxs ml-auto"
          onClick={handleNext}
          type="button"
        >
          Next
        </button>
      </div>
      <div className="px-6 lg:px-28 mt-4">
        {errors && (
          <p className="text-red-500 text-xs text-center italic">{errors}</p>
        )}
      </div>                
    </Fade>
  )
}

export default ProjectInfo
